import { stickyNavOnScroll } from '../utils/stickyNavOnScroll';
import { enableScrollLock, disableScrollLock } from '../utils/scrollLock';

export let nav;
let headerNav;

export function toggleMenuOpen() {
    if (nav.classList.contains('nav--open')) {
        disableScrollLock();
        nav.classList.remove('nav--open');

        if (window.pageYOffset > 50) {
            setTimeout(() => nav.classList.add('going-up'), 40);
        }

    } else {
        enableScrollLock();
        nav.classList.add('nav--open');
    }
}

function clickHeaderNav(e) {
    if (e.target.closest('li.active')) {
        if (headerNav.classList.contains('js-open')) {
            headerNav.classList.remove('js-open');
        } else {
            headerNav.classList.add('js-open');
        }
    }
}

function toggleUserNav(e) {
    const elm = e.currentTarget;

    if (elm.classList.contains('js-active')) {
        elm.classList.remove('js-active');
    } else {
        elm.classList.add('js-active');
    }
}

export function setupNav(selector = '.nav', sticky = true) {
    nav = document.body.querySelector(selector);

    if (nav) {
        const navBtn = nav.querySelector('.nav__btn');

        navBtn.addEventListener('click', toggleMenuOpen);

        if (sticky) {
            stickyNavOnScroll(nav, 'nav--sticky', 30, 'going-up');
        }

        const user = nav.querySelector('.nav__user');

        if (user) {
            user.addEventListener('click', toggleUserNav);
        }
    }

    headerNav = document.querySelector('.header__nav');

    if (headerNav) {
        headerNav.addEventListener('click',clickHeaderNav);
    }
}
