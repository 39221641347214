import { map, mapSetup } from './search-ad';
import { htmlDecode } from '../utils/helpers';
import { getCookie, setCookie } from '../utils/cookie';

export function setupCreateAd() {
    let stepBarSteps = document.querySelectorAll('.step-bar__item');
    const supportWrap = document.querySelector('.create-ad__support');
    let supportSteps = document.querySelectorAll('.create-ad__support__step');
    const form = document.querySelector('.create-ad__form');
    const createMap = document.querySelector('.create-ad__map');
    const mapDraw = document.getElementById('map-draw');
    const mapUndo = document.getElementById('map-undo');
    const mapNewPath = document.getElementById('map-new-path');
    const googleMapsDataField = document.getElementById('GoogleMapsdata');
    let currentActiveStep = 0;
    let steps;
    let mapLoaded = false;
    let marker;
    let coordinates = [];
    const paths = [];

    function validateCurrentStep(currentStep) {
        stepBarSteps = document.querySelectorAll('.step-bar__item');
        supportSteps = document.querySelectorAll('.create-ad__support__step');

        const requiredFields = currentStep.querySelectorAll('input[required],textarea[required]');
        const stepState = {
            isValid: true,
            validFields: [],
            invalidFields: []
        };

        Array.from(requiredFields).forEach(field => {
            if (!field.checkValidity()) {
                stepState.invalidFields.push(field);
                stepState.isValid = false;
            } else {
                stepState.validFields.push(field);
            }
        });

        return stepState;
    }

    function updateStepBar(prevActiveStep) {
        const stepBarsActive = Array.from(stepBarSteps).filter(x => x.classList.contains('step-bar__item--active'));

        stepBarsActive.forEach(active => {
            active.classList.remove('step-bar__item--active');
        });

        stepBarSteps[currentActiveStep - 1].classList.add('step-bar__item--active');
    }

    function updateSupport(prevActiveStep) {
        const supportStepActive = Array.from(stepBarSteps).filter(x => x.classList.contains('step-bar__item--active'));

        supportStepActive.forEach(active => {
            active.classList.remove('create-ad__support__step--active');
        });

        supportSteps[currentActiveStep - 1].classList.add('create-ad__support__step--active');
    }

    function getPreviousStep(currentStep) {
        stepBarSteps[currentActiveStep].classList.remove('step-bar__item--active');
        supportSteps[currentActiveStep].classList.remove('create-ad__support__step--active');
        currentActiveStep = currentActiveStep - 1;
        let prevStep = currentStep.previousElementSibling;
        let skipStep = false;

        if (prevStep.hasAttribute('data-action')) {
            if (prevStep.getAttribute('data-action') == 'skip-small-screens') {
                const isSmallScreen = window.matchMedia('(max-width: 767px)');

                if (isSmallScreen.matches) {
                    skipStep = true;
                }
            }
        }

        if (skipStep) {
            currentStep.classList.remove('create-ad__form__step--done');
            prevStep = getPreviousStep(prevStep);
        }

        stepBarSteps[currentActiveStep].classList.add('step-bar__item--active');
        supportSteps[currentActiveStep].classList.add('create-ad__support__step--active');

        return prevStep;
    }

    function prevStep(e) {
        const activeStep = e.currentTarget.closest('.create-ad__form__step--active');

        if (activeStep) {
            const nextStep = getPreviousStep(activeStep);

            if (nextStep) {
                activeStep.classList.remove('create-ad__form__step--active');
                nextStep.classList.remove('create-ad__form__step--done');
                nextStep.classList.add('create-ad__form__step--active');

                document.documentElement.scrollTop = 0;
                document.body.scrollTop = 0;
            }
        }

        if (createMap) {
            createMap.classList.remove('js-active');
        }
    }

    function getNextStep(currentStep) {
        stepBarSteps[currentActiveStep].classList.remove('step-bar__item--active');
        supportSteps[currentActiveStep].classList.remove('create-ad__support__step--active');
        currentActiveStep = currentActiveStep + 1;
        let nextStep = currentStep.nextElementSibling;
        let skipStep = false;

        if (nextStep.hasAttribute('data-action')) {
            if (nextStep.getAttribute('data-action') == 'skip-small-screens') {
                const isSmallScreen = window.matchMedia('(max-width: 767px)');

                if (isSmallScreen.matches) {
                    skipStep = true;
                }
            }
        }

        if (skipStep) {
            currentStep.classList.add('create-ad__form__step--done');
            nextStep = getNextStep(nextStep);
        }

        stepBarSteps[currentActiveStep].classList.add('step-bar__item--active');
        supportSteps[currentActiveStep].classList.add('create-ad__support__step--active');

        return nextStep;
    }

    function nextStep(e) {
        const activeStep = e.currentTarget.closest('.create-ad__form__step--active');

        if (activeStep) {
            const currentStepState = validateCurrentStep(activeStep);

            currentStepState.validFields.forEach(field => {
                if (field.type.toLowerCase() == 'radio' || field.type.toLowerCase() == 'checkbox') {
                    const fieldset = field.closest('.form__fieldset');

                    if (fieldset) {
                        fieldset.classList.remove('js-error');
                    }
                } else {
                    field.classList.remove('js-error');
                }
            });

            if (currentStepState.isValid) {
                const nextStep = getNextStep(activeStep);

                if (nextStep) {
                    activeStep.classList.add('create-ad__form__step--done');
                    activeStep.classList.remove('create-ad__form__step--active');
                    nextStep.classList.add('create-ad__form__step--active');

                    const showMapTrigger = nextStep.querySelector('[data-action="show-map"]');

                    if (showMapTrigger) {
                        if (showMapTrigger.checked) {
                            setTimeout(function() {
                                clickShowMapTrigger();
                            },500);
                        }
                    }

                    document.documentElement.scrollTop = 0;
                    document.body.scrollTop = 0;
                }
            } else {
                currentStepState.invalidFields.forEach(field => {
                    if (field.type.toLowerCase() == 'radio' || field.type.toLowerCase() == 'checkbox') {
                        const fieldset = field.closest('.form__fieldset');

                        if (fieldset) {
                            fieldset.classList.add('js-error');
                        }
                    } else {
                        field.classList.add('js-error');
                    }
                });
            }
        }

        if (createMap) {
            createMap.classList.remove('js-active');
        }
    }

    function clickMapUndo() {
        coordinates.pop();

        paths[paths.length - 1].setPaths(coordinates);
        paths[paths.length - 1].setMap(map);

        updatePathsField();

        if (coordinates.length == 1) {
            placeMarker(coordinates[0]);
        }

        if (mapNewPath && coordinates.length < 3) {
            mapNewPath.classList.add('js-disabled');
        }

        if (coordinates.length == 0) {
            removeMarker(marker);
            paths.pop();

            if (paths.length > 0) {
                coordinates = paths[paths.length - 1].getPath().getArray();
            }
        }

        if (mapUndo && paths.length == 0) {
            mapUndo.classList.add('js-disabled');
        }
    }

    function clickMapNewPath() {
        coordinates = [];

        paths[paths.length] = new google.maps.Polygon({
            paths: coordinates,
            strokeColor: '#aa0000',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#aa0000',
            fillOpacity: 0.35,
            clickable: false
        });
    }

    function updatePathsField() {
        googleMapsDataField.value = '';
        const hasMultiPaths = paths.length > 1;

        paths.forEach(path => {
            if (hasMultiPaths) {
                if (googleMapsDataField.value.length > 0) {
                    googleMapsDataField.value += ',[';
                } else {
                    googleMapsDataField.value = '[';
                }
            }

            path.getPaths().forEach(coordinates => {
                coordinates.forEach((coordinate, index) => {
                    if (index == 0) {
                        googleMapsDataField.value += `${coordinate.toString()}`;
                    } else {
                        googleMapsDataField.value += `,${coordinate.toString()}`;
                    }
                });
            });

            if (hasMultiPaths) {
                googleMapsDataField.value += ']';
            }
        });
    }

    function loadMap() {
        let drawMode = false;

        if (createMap.hasAttribute('data-map-data')) {
            const mapData = createMap.getAttribute('data-map-data');
            //const hasMulti = mapData.indexOf('[') != -1;
            const mapDataPaths = mapData.split('],[');

            mapDataPaths.forEach((path,pIndex) => {
                const _path = path.replace(']','')
                    .replace('[','');
                const _coordinates = _path.split('),(');
                const pathCoordinates = [];

                _coordinates.forEach((coordinate,cIndex) => {
                    let _coordinate = coordinate.replace(')', '')
                        .replace('(', '')
                        .replace(' ','');
                    _coordinate = _coordinate.split(',');
                    const _lat = parseFloat(_coordinate[0]);
                    const _lng = parseFloat(_coordinate[1]);
                    const gmCoordinate = new google.maps.LatLng(_lat, _lng);;
                    pathCoordinates.push(gmCoordinate);

                    if (pIndex == 0 && cIndex == 0) {
                        map.setCenter(gmCoordinate);
                        map.setZoom(12);
                    }
                });

                coordinates = pathCoordinates;

                paths[paths.length] = new google.maps.Polygon({
                    paths: pathCoordinates,
                    strokeColor: '#aa0000',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: '#aa0000',
                    fillOpacity: 0.35,
                    clickable: false
                });

                if (paths.length > 0) {
                    paths[paths.length - 1].setMap(map);
                } else {
                    paths[0].setMap(map);
                }
            });

            if (mapUndo) {
                mapUndo.classList.remove('js-disabled');
            }

            if (mapNewPath) {
                mapNewPath.classList.remove('js-disabled');
            }

            drawMode = true;
            updatePathsField();
        } else {
            paths[paths.length] = new google.maps.Polygon({
                paths: coordinates,
                strokeColor: '#aa0000',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#aa0000',
                fillOpacity: 0.35,
                clickable: false
            });
        }

        google.maps.event.addListener(map, 'click', function (event) {
            if (drawMode) {
                coordinates.push(event.latLng);

                if (paths.length > 0) {
                    paths[paths.length - 1].setPaths(coordinates);
                } else {
                    paths[0].setPaths(coordinates);
                }

                if (coordinates.length == 1) {
                    placeMarker(event.latLng);
                } else if (coordinates.length > 1) {
                    if (paths.length > 0) {
                        paths[paths.length - 1].setMap(map);
                    } else {
                        paths[0].setMap(map);
                    }

                    removeMarker(marker);

                    if (mapUndo) {
                        mapUndo.classList.remove('js-disabled');
                    }
                }

                if (coordinates.length >= 3) {
                    if (mapNewPath) {
                        mapNewPath.classList.remove('js-disabled');
                    }
                }

                updatePathsField();
                enableDrawing();
            }
        });

        function enableDrawing() {
            drawMode = true;
        }

        if (mapDraw) {
            mapDraw.addEventListener('click', enableDrawing);
        }

        if (mapUndo) {
            mapUndo.addEventListener('click', clickMapUndo);
        }

        if (mapNewPath) {
            mapNewPath.addEventListener('click', clickMapNewPath);
        }

        mapLoaded = true;
    }

    function showMapIntrodcution() {
        const mapSteps = document.querySelectorAll('.create-ad__map-tip-list li');

        function clickNext(e) {
            const li = e.currentTarget.closest('li');
            li.classList.remove('js-active');

            if (li.nextElementSibling) {
                li.nextElementSibling.classList.add('js-active');
            } else {
                setCookie('mapIntroduction','1',365);
            }
        }

        mapSteps.forEach((elm, index) => {
            if (index == 0) {
                elm.classList.add('js-active');
            } else {
                elm.classList.remove('js-active');
            }

            const next = elm.querySelector('.next');

            if (next) {
                next.addEventListener('click',clickNext);
            }
        });
    }

    function clickShowMapTrigger(e) {
        if (createMap) {
            createMap.classList.add('js-active');

            const checkMap = setInterval(function() {
                if (mapSetup) {
                    loadMap();
                    clearInterval(checkMap);
                }
            },10);

            if (getCookie('mapIntroduction') == '') {
                showMapIntrodcution();
            }
        }
    }

    function clickHideMapTrigger(e) {
        if (createMap) {
            createMap.classList.remove('js-active');
        }
    }

    if (form) {
        steps = form.querySelectorAll('.create-ad__form__step');

        Array.from(steps).forEach(step => {
            const stepPrevBtn = step.querySelector('.create-ad__btn--prev-step');
            const stepNextBtn = step.querySelector('.create-ad__btn--next-step');

            if (stepPrevBtn) {
                stepPrevBtn.addEventListener('click', prevStep);
            }

            if (stepNextBtn) {
                stepNextBtn.addEventListener('click', nextStep);
            }
        });

        const showMapTrigger = form.querySelector('[data-action="show-map"]');
        const hideMapTrigger = form.querySelector('[data-action="hide-map"]');

        if (showMapTrigger) {
            showMapTrigger.addEventListener('click', clickShowMapTrigger);
        }

        if (hideMapTrigger) {
            hideMapTrigger.addEventListener('click', clickHideMapTrigger);
        }
    }

    function placeMarker(location) {
        marker = new google.maps.Marker({
            position: location,
            map
        });
    }

    function removeMarker(marker) {
        marker.setMap(null);
    }

    const fieldsetExpanders = document.querySelectorAll('.form__fieldset-expander');
    let currentlyExpanded = -1;

    function clickExpander(e) {
        const expander = e.currentTarget;
        const fieldset = expander.nextElementSibling;

        if (currentlyExpanded != -1 && currentlyExpanded != fieldset) {
            currentlyExpanded.classList.add('form__fieldset--collapse');
        }

        if (fieldset.classList.contains('form__fieldset')) {
            if (fieldset.classList.contains('form__fieldset--collapse')) {
                fieldset.classList.remove('form__fieldset--collapse');
                currentlyExpanded = fieldset;
            } else {
                fieldset.classList.add('form__fieldset--collapse');
                currentlyExpanded = -1;
            }
        }
    }

    fieldsetExpanders.forEach(expander => {
        expander.addEventListener('click', clickExpander);
    });

    function clickSupportWrap(e) {
        if (supportWrap.classList.contains('js-expanded')) {
            supportWrap.classList.remove('js-expanded');
        } else {
            supportWrap.classList.add('js-expanded');
        }
    }

    if (supportWrap) {
        supportWrap.addEventListener('click',clickSupportWrap);
    }
}
