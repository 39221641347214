import { enableScrollLock, disableScrollLock } from '../utils/scrollLock';
import { onWindowResize } from '../utils//windowResize';

export function setupChat() {
    const messsageInput = document.getElementById('groupmessage');

    if (messsageInput) {
        messsageInput.addEventListener('focus',enableScrollLock);
        messsageInput.addEventListener('blur',disableScrollLock);
    }

    const adChatExpand = document.querySelector('.ad-chat__expand');
    const adChatHide = document.querySelector('.ad-chat__hide');

    function expandChat(e) {
        const elm = e.currentTarget;
        const chat = elm.closest('.ad-chat');

        if (chat) {
            if (chat.classList.contains('js-expanded')) {
                chat.classList.remove('js-expanded');
            } else {
                chat.classList.add('js-expanded');
            }
        }
    }

    function hideChat(e) {
        const elm = e.currentTarget;
        const chat = elm.closest('.ad-chat');

        if (chat) {
            if (chat.classList.contains('js-hidden')) {
                chat.classList.remove('js-hidden');
            } else {
                chat.classList.add('js-hidden');
            }
        }
    }

    const mySheerHunt = document.querySelector('.my-sheerhunt');

    if (adChatExpand) {
        adChatExpand.addEventListener('click',expandChat);
    }

    if (adChatHide) {
        adChatHide.addEventListener('click',hideChat);
    }

    const _chat = document.querySelector('.ad-chat');

    function itsPortraitMode() {
        if (_chat && !mySheerHunt) {
            _chat.classList.add('js-hidden');
        }
    }

    function itsNotPortraitMode() {
        if (_chat && !mySheerHunt) {
            _chat.classList.remove('js-hidden');
        }
    }

    function checkScreen() {
        const mql = window.matchMedia('(orientation: portrait)');

        if (mql.matches) {
            itsPortraitMode();
        } else {
            itsNotPortraitMode();
        }
    }

    onWindowResize(checkScreen);
    checkScreen();
}
