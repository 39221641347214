import { htmlDecode } from '../utils/helpers';

export let userLat;
export let userLng;

export function setupListView() {
    const geocodeListViews = document.querySelectorAll('.list-view[data-user-lat][data-user-lng]');

    function getDistance(lat1, lon1, lat2, lon2) {
        const p = 0.017453292519943295; // Math.PI / 180
        const c = Math.cos;
        const a = 0.5 - c((lat2 - lat1) * p) / 2 + c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p)) / 2;

        return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
    }

    geocodeListViews.forEach(view => {
        userLat = view.getAttribute('data-user-lat').replace(',', '.');
        userLng = view.getAttribute('data-user-lng').replace(',', '.');

        /*let road = htmlDecode(view.getAttribute('data-road'));
        road = htmlDecode(road);

        const houseNumber = view.getAttribute('data-house-number');
        const zipCode = view.getAttribute('data-zip-code');
        const fetchUrl = `https://dawa.aws.dk/adresser?vejnavn=${road}&husnr=${houseNumber}&postnr=${zipCode}`;

        fetch(fetchUrl)
            .then(response => response.json())
            .then(data => {
                userLat = data[0].adgangsadresse.adgangspunkt.koordinater[1];
                userLng = data[0].adgangsadresse.adgangspunkt.koordinater[0];*/

        const viewElements = view.querySelectorAll('.list-view__item[data-lat][data-lng]');

        viewElements.forEach(element => {
            const lat = element.getAttribute('data-lat');
            const lng = element.getAttribute('data-lng');
            const distance = getDistance(userLat, userLng, lat, lng);

            if (distance < 30) {
                element.classList.remove('list-view__item--hidden');
                element.innerHTML = `<span>Afstand: ${distance.toFixed(1)} km.</span>${element.innerHTML}`;
            } else {
                element.parentNode.removeChild(element);
            }
        });
        /*});*/
    });
}
