import { cssAnimateNewHeight } from '../utils/animation';

/**
 * Attention
 * Be aware that this module is dependent on support for Element.prototype.closest
 * This is included as default in https://polyfill.io/v3/polyfill.min.js
 */

// Used keycodes
const keycode = {
    space: 32,
    arrowUp: 38,
    arrowDown: 40,
    home: 36,
    end: 35
};

let activeAccordion;
let index = 0;

/**
 * The Accordion class uses the cssAnimateNewHeight function to reveal hidden content.
 * Default element selector is data-action*="accordion"
 * If data-action is set to "accordion-switch", active accordions will close when another is opened.
 *
 * @param {HTMLElement} trigger
 * @constructor
 */
export class Accordion {

    /**
     * The constructor is fired once the class is instantiated.
     *
     * @param {HTMLElement} trigger
     */
    constructor(trigger) {
        this.dom = {
            trigger,
            container: trigger.parentNode.querySelector('.accordion__content') || trigger.nextElementSibling
        };

        this.settings = {
            type:  trigger.getAttribute('data-action')
        };

        this.index = index;

        index++;

        this.dom.trigger.addEventListener('click', e => {
            if (!(event.ctrlKey || event.metaKey || event.shiftKey)) {
                e.preventDefault();
            }
            if (activeAccordion && this.index !== activeAccordion.index) {
                // Dobbelt check added to prevent delayed dobbelt opening
                if (activeAccordion.dom.container.classList.contains('open')) {
                    cssAnimateNewHeight(activeAccordion.dom.container, activeAccordion.dom.trigger, true);
                    this.dom.container.setAttribute('aria-hidden', true);
                    activeAccordion.dom.trigger.setAttribute('aria-expanded', 'false');
                }
            }

            cssAnimateNewHeight(this.dom.container, this.dom.trigger, true);
            this.dom.container.setAttribute('aria-hidden', false);

            // Set the inverted value of aria-expanded
            const ariaExpanded = this.dom.trigger.getAttribute('aria-expanded') || 'false';
            this.dom.trigger.setAttribute('aria-expanded', ariaExpanded === 'false');

            if (this.settings.type === 'accordion-switch') {
                activeAccordion = this;
            }
        });
    }
}

function openAccordByHashId() {
    if (window.location.hash.indexOf('accordion') > -1) {
        const accordionContent = document.getElementById(window.location.hash.replace('#', ''));
        if (accordionContent) {
            const accord = document.getElementById(accordionContent.getAttribute('aria-labelledby'));
            accord.click();
        }
    }
}

/**
 * Initialise Accordions with this function
 * Will only run if given selector elements are found in DOM
 *
 * @param {string} selector - element selector string
 */

export function setupAccordions(selector = '[data-action*="accordion"]') {
    const accordions = document.querySelectorAll(selector);

    if (accordions) {
        for (let i = 0; i < accordions.length; i++) {
            void new Accordion(accordions[i]);
        }

        openAccordByHashId();
    }
}

window.addEventListener('keydown', event => {
    const focusedElement = document.activeElement;

    if (focusedElement) {
        /*  This code needs to be fixed!
            It makes it imposible to type spaces (ex. in input fields)

            if (event.keyCode === keycode.space) {
            // Toggle accordion
            event.preventDefault();
            focusedElement.click();

        } */
        if (focusedElement.closest('.accordion') != undefined) {
            if (event.keyCode === keycode.arrowUp) {
                // Focus prevoius accordion
                if (focusedElement.closest('.accordion').previousElementSibling && focusedElement.closest('.accordion').previousElementSibling.querySelector('.accordion__head')) {
                    focusedElement.closest('.accordion').previousElementSibling.querySelector('.accordion__head').focus();
                }
            }
            if (event.keyCode === keycode.arrowDown) {
                // Focus next accordion
                if (focusedElement.closest('.accordion').nextElementSibling && focusedElement.closest('.accordion').nextElementSibling.querySelector('.accordion__head')) {
                    focusedElement.closest('.accordion').nextElementSibling.querySelector('.accordion__head').focus();
                }
            }
            if (event.keyCode === keycode.home) {
                // Focus first accordion
                if (focusedElement.closest('.accordion').parentNode.querySelector('.accordion:first-child .accordion__head')) {
                    event.preventDefault();
                    focusedElement.closest('.accordion').parentNode.querySelector('.accordion:first-child .accordion__head').focus();
                }
            }
            if (event.keyCode === keycode.end) {
                // Focus last accordion
                if (focusedElement.closest('.accordion').parentNode.querySelector('.accordion:last-child .accordion__head')) {
                    event.preventDefault();
                    focusedElement.closest('.accordion').parentNode.querySelector('.accordion:last-child .accordion__head').focus();
                }
            }
        }
    }
});
